@import '~antd/dist/antd.less';
@import 'variables.less';

body {
  margin: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
body::-webkit-scrollbar {
  width: 8px;
}
body::-webkit-scrollbar-track {
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(95, 95, 95, 0.2);

}
body::-webkit-scrollbar-thumb {
  background: rgba(121, 121, 121, 0.5);
  border-radius: 3px;
}
body::-webkit-scrollbar-thumb:hover {
  background: #5f94e4;
}

.menuBody::-webkit-scrollbar {
  width: 5px;
}
.menuBody::-webkit-scrollbar-track {
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(95, 95, 95, 0.2);

}
.menuBody::-webkit-scrollbar-thumb {
  background: rgba(121, 121, 121, 0.5);
  border-radius: 3px;
}
.menuBody::-webkit-scrollbar-thumb:hover {
  background: #5f94e4;
}

.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
}

