.ant-table-thead > tr > th {
  font-weight: 600 !important;
}
.ant-table table {
  border: 1px solid #e2e2e2;
  border-radius: 15px !important;
  font-weight: 300;
}
.ant-menu-inline {
  border-right: 0px solid #f0f0f0;
}
.ant-page-header {
  border: none !important;
  border-top: 1px solid #eaedf0 !important;
  border-bottom: 1px solid #eaedf0 !important;
}
.ant-table-wrapper {
  margin: 10px !important;
}
.ant-page-header-heading-title {
  margin-right: 20px !important;
}

.ant-upload-list-text .ant-upload-list-item-name {
  color: #1890ff;
}

.ant-input {
  border-color: #aaa;
}

.ant-input-number {
  border-color: #aaa !important;
}

.ant-picker {
  border-color: #aaa !important;
}

.ant-select-selector {
  border-color: #aaa !important;
}

.ant-select-arrow {
  color: #aaa;
}

.ant-select-selection-placeholder {
  color: #aaa;
}

.ant-menu-item,
.ant-menu-submenu {
  font-weight: 300 !important;
}
.ant-menu-item-selected {
  font-weight: 600 !important;
}
.ant-empty {
  padding: 70px 0;
}
.ant-table-thead > tr > th {
  padding: 25px !important;
}
.ant-dropdown-menu-item {
  padding: 12px 25px !important;
}
.ant-table-cell {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 250px !important;
  padding: 14px 25px !important;
}

.frill-embedded {
  width: 1000px;
  height: 500px;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
}

.card-container > .ant-tabs-card .ant-tabs-content {
  margin-top: -16px !important;
}
.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 0 !important;
}
.card-container > .ant-tabs-card > .ant-tabs-nav::before {
  display: none !important;
}
.card-container > .ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent !important;
}
.ant-tabs-nav {
  border-bottom: #e2e2e2 solid 2px;
  margin: 15px !important;
}
.card-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  border-bottom: #7494ea solid 2px !important;
  background: #fff !important;
  /* border-color: #fff !important; */
}
#components-tabs-demo-card-top .code-box-demo {
  overflow: hidden !important;
  background: #f5f5f5 !important;
}
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-content {
  height: 120px !important;
  margin-top: -8px !important;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab {
  background: transparent !important;
  border-color: transparent !important;
}
[data-theme='dark'] #components-tabs-demo-card-top .code-box-demo {
  background: #000 !important;
}
[data-theme='dark']
  .card-container
  > .ant-tabs-card
  .ant-tabs-content
  > .ant-tabs-tabpane {
  background: #141414 !important;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  background: #141414 !important;
  border-color: #141414 !important;
}

.ant-tag {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 100% !important;
}

.client-column {
  text-overflow: unset !important;
}
